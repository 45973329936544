<template>
  <v-dialog v-model="show" persistent :fullscreen="isPhone" max-width="800">
    <v-card
      class="d-flex flex-column noscroll charcoal "
      v-touch:swipe.left="cancel"
    >
      <BaseCloseDialogHeader
        :heading="mode == 'swap' ? 'Swap Exercise' : 'Add Another Exercise'"
        @cancel="cancel"
      />
      <template v-if="!loaded">
        <v-progress-linear
          color="cyan"
          indeterminate
          rounded
          height="1"
          class="mt-5"
        />
        <v-card-text class="silver--text text-center mt-2">
          {{ loadingMessage }}
        </v-card-text>
      </template>
      <template v-else>
        <v-card-text class="silver--text pt-2 pb-0 px-2">
          <v-card-text v-if="mode == 'swap'" class="pa-0 mb-2 silver--text">
            You are swapping
            <span class="paper--text"> {{ swapExercise.name }} </span>.
            <v-spacer />
            Choose the new exercise below.
          </v-card-text>
          <v-card-text v-else class="pa-0 mb-2 silver--text">
            Find a new exercise below.
          </v-card-text>
          <v-row class="pb-0 pt-0" dense>
            <v-col>
              <BaseSearch
                dark
                :searchFor="searchFor"
                :items="loadedList"
                :label="loadedList.length + ' Exercises'"
                :searchCounter="searchCounter"
                @search_results="setResults"
                @reset="resetSearch"
                :searchIn="['name', 'alternate_name']"
              />
            </v-col>
            <v-col cols="12" class="px-0">
              <v-card
                rounded
                flat
                v-for="item in exercises"
                :key="item.id"
                class="pa-2 mx-2 charcoal charcoalTile progressActive--text mb-2"
                @click="setSelectedExercise(item)"
              >
                <v-card-actions
                  :class="textSizeSmall + ' px-1 pt-1 pb-0 paper--text'"
                >
                  {{ item.name }}
                  <v-spacer />
                  <v-icon v-if="isSelected(item)" color="progressActive" right>
                    mdi-check-circle
                  </v-icon>
                  <template v-else>
                    <v-icon
                      small
                      v-if="item.commonSwap"
                      color="gold"
                      right
                    >
                      mdi-pin
                    </v-icon>
                    <v-icon
                      small
                      v-if="item.is_favorite == 'Yes'"
                      color="accent"
                      right
                    >
                      mdi-heart
                    </v-icon>
                   
                  </template>
                </v-card-actions>
                <v-card-actions class="px-1 py-0 caption">
                  <span title="Details" class="silver--text">
                    {{ item.force }}, {{ item.utility }}, {{ item.mechanics }}
                  </span>
                  <v-spacer />
                  <span title="Dificulty" class="silver--text">
                    {{ item.dificulty }}
                  </span>
                </v-card-actions>
                <template v-if="isSelected(item)">
                  <v-card-actions class="mt-2 pa-0">
                    <v-select
                      class="rounded-lg"
                      solo
                      height="70"
                      background-color="charcoal lighten-2"
                      chips
                      :key="switchNum"
                      large
                      dark
                      v-model="repschemeid"
                      :items="repSchemes"
                      label="Select Repscheme"
                      item-text="name"
                      item-value="id"
                      color="progressActive"
                      item-color="progressActive"
                      menu-icon="mdi-arrow-bottom-right"
                      return-object
                      @input="setSelectedExercise(item)"
                      :menu-props="{
                        closeOnClick: true,
                        maxHeight: 280,
                        transition: 'fab-transition'
                      }"
                    >
                      <template
                        v-slot:selection="{
                          item,
                          index,
                          selected,
                          disabled,
                          remove
                        }"
                      >
                        <v-chip
                          class="silver lighten-3 pa-4 text-h6"
                          :key="index"
                          :input-value="item.value"
                          :disabled="disabled"
                        >
                          <span class="black--text"> {{ item.name }}</span>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item, on, attrs }">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                          class="transparent"
                        >
                          <v-list-item-content class="paper lighten-2">
                            <v-list-item-title class="text-h6 black--text">{{
                              item.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-card-actions>
                  <v-card-actions class="pr-0 py-0 mt-n4 silver--text">
                    {{ item.disabled ? 'Already Used' : '' }}
                    <v-spacer />
                    <BaseActionButton
                      v-if="mode == 'swap'"
                      :disabled="selectedExercise == null || item.disabled"
                      tonal
                      icon="mdi-swap-horizontal-circle-outline"
                      label="Swap"
                      color="charcoal lighten-2"
                      large
                      @clickedThis="swapExerciseWorkout"
                    />
                    <BaseActionButton
                      v-else
                      :disabled="selectedExercise == null || item.disabled"
                      text
                      icon="mdi-plus-circle-outline"
                      label="Add"
                      color="paper"
                      large
                      @clickedThis="addExerciseToWorkout"
                    />
                  </v-card-actions>
                </template>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-close"
            label="Cancel"
            color="info"
            plain
            :large="!isPhone"
            @clickedThis="cancel"
          />
          <v-spacer />
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  name: 'AddOrSwapExercise',
  mixins: [util],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    packed: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    workoutInstanceId: {
      type: String
    },
    swapExercise: {
      type: Object
    },
    currentExercises: {
      type: Array
    }
  },
  data() {
    return {
      switchNum: 0,
      selectedExercise: null,
      exercise: '',
      len: 0,
      added: '',
      searchFor: '',
      searchCounter: 0,
      exercises: [],
      loadedList: [],
      repSchemes: [],
      repschemeid: { id: 'hzWVL', sets: 5 },
      loadingMessage: 'Loading',
      loaded: false
    }
  },
  beforeMount() {},
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  watch: {
    show(value) {
      this.reset()
      this.$store.dispatch('session/toggleModal', value)
      if (value) {
        this.searchFor = ''
        this.searchCounter++
        this.loadRepschemes()
        this.loadExercises()
      }
    }
  },
  computed: {
    itemsCount() {
      return this.exercises.length
    },
    numberOfPages() {
      return Math.ceil(this.exercises.length / this.itemsPerPage)
    },
    ...appConfig
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        this.cancel()
      }
    },
    setResults(value) {
      this.exercises = value
    },

    setSelectedExercise(item) {
      item.repschemeid = this.repschemeid.id
      item.sets = this.repschemeid.sets
      this.selectedExercise = item
    },
    isSelected(item) {
      if (this.selectedExercise == null) return false
      return item.id === this.selectedExercise.id
    },
    reset() {
      this.selectedExercise = null
      this.resetSearch()
    },
    resetSearch() {
      this.searchFor = ''
      this.exercises = this.loadedList
    },
    cancel() {
      this.$emit('cancel')
    },
    async addExerciseToWorkout() {
      try {
        this.loaded = false
        const response = await axios.post(`${this.baseURL}/workout/exercise`, {
          workoutInstanceId: this.workoutInstanceId,
          exercise: this.selectedExercise,
          now: this.now()
        })

        if (response.status === 200) {
          this.reset()
          this.$emit('saved')
          this.$store.dispatch(
            'notification/addNotification',
            'New Exercise Added',
            2000,
            true,
            { root: true }
          )
          this.loadingMessage = 'Loading'
          this.loaded = true
        } else {
          this.loaded = true
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            { root: true }
          )
        }
      } catch (error) {
        // Handle the error appropriately, such as sending it to a logging service or displaying a message to the user
        this.$store.dispatch(
          'notification/addErrors',
          error.message,
          5000,
          true,
          { root: true }
        )
      }
    },
    async swapExerciseWorkout() {
      this.loadingMessage = 'Swapping'
      this.loaded = false
      try {
        const response = await axios.post(
          `${this.baseURL}/workout/swap/exercise`,
          {
            workoutInstanceId: this.workoutInstanceId,
            newexercise: this.selectedExercise,
            oldexerciseid: this.swapExercise.id,
            now: this.now()
          }
        )
        if (response.status === 200) {
          this.reset()
          this.loaded = true
          this.loadingMessage = 'Loading'
          this.$emit('saved')
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            { root: true }
          )
        }
      } catch (error) {
        this.loaded = true
        // Handle the error appropriately, such as sending it to a logging service or displaying a message to the user
        this.$store.dispatch(
          'notification/addErrors',
          error.message,
          5000,
          true,
          { root: true }
        )
      }
    },

    async getMostSwappedExercises(oldexerciseid) {
      try {
        const response = await axios.get(
          `${this.baseURL}/exercises/most-swapped/${oldexerciseid}`
        )
        if (response.status === 200) {
          return response.data.data
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            { root: true }
          )
          return []
        }
      } catch (error) {
        // handle the error appropriately
        return []
      }
    },
    async loadExercises() {
      this.loadingMessage = 'Loading'
      let localExercises = localStorage.getItem('exercises')

      if (localExercises) {
        this.exercises = JSON.parse(localExercises)
        this.loadedList = [...this.exercises]
        this.len = this.exercises.length
      }

      try {
        this.loaded = false
        const response = await axios.get(
          `${this.baseURL}/exercises/favoritesfirst`
        )
        if (response.status === 200) {
          if (!localExercises || this.exercises.length === 0) {
            this.exercises = response.data.data
          }

          // Fetch the most swapped exercise IDs
          const mostSwappedExercises = await this.getMostSwappedExercises(
            this.swapExercise.id // Assuming you have the old exercise ID in this.swapExercise
          )

          // Create a set of most swapped exercise IDs for quick lookup
          const mostSwappedExerciseIds = new Set(
            mostSwappedExercises.map(ex => ex.newexerciseid)
          )

          // Reorder exercises based on most swapped exercise IDs and set properties
          this.exercises.sort((a, b) => {
            const indexA = mostSwappedExercises.findIndex(
              ex => ex.newexerciseid === a.id
            )
            const indexB = mostSwappedExercises.findIndex(
              ex => ex.newexerciseid === b.id
            )

            // Set commonSwap property
            a.commonSwap = mostSwappedExerciseIds.has(a.id)
            b.commonSwap = mostSwappedExerciseIds.has(b.id)

            // Check if the exercise id exists in the currentExercises array
            const isInCurrentExercisesA = this.currentExercises.some(
              current => current.id === a.id
            )
            const isInCurrentExercisesB = this.currentExercises.some(
              current => current.id === b.id
            )

            // Check if the exercise id is not the id in the swapExercise object
            const isNotSwapExerciseA = a.id !== this.swapExercise.id
            const isNotSwapExerciseB = b.id !== this.swapExercise.id

            // Set the disabled property
            a.disabled = isInCurrentExercisesA && isNotSwapExerciseA
            b.disabled = isInCurrentExercisesB && isNotSwapExerciseB

            // Sorting logic
            if (indexA === -1) return 1
            if (indexB === -1) return -1
            return indexA - indexB
          })

          this.loadedList = [...this.exercises]
          this.len = this.exercises.length
          localStorage.setItem('exercises', JSON.stringify(this.exercises))
          this.loaded = true
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            { root: true }
          )
          this.loaded = true
        }
      } catch (error) {
        this.loaded = true
      }
    },
    async loadRepschemes() {
      let localRepschemes = localStorage.getItem('repschemes')

      if (localRepschemes) {
        this.repSchemes = JSON.parse(localRepschemes)
        this.selectedScheme = this.repSchemes.find(
          item => item.id == this.repschemeid
        )
      }

      try {
        const response = await axios.get(`${this.baseURL}/tools/repschemes/all`)

        if (response.status === 200) {
          this.repSchemes = response.data.data
          localStorage.setItem('repschemes', JSON.stringify(this.repSchemes))

          if (!localRepschemes) {
            this.selectedScheme = this.repSchemes.find(
              item => item.id == this.repschemeid
            )
          }
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            { root: true }
          )
        }
      } catch (error) {
        // handle the error appropriately
      }
    }
  }
}
</script>
